<template>
  <vs-row class="w-100 mt-4">
    <vs-col vs-w="12">
      <h5>Logo (Organisation / rechts oben)</h5>
      <p>
        Diese Grafik wird sowohl als Organisations-Logo in der App, als auch auf
        der Vorderseite des Ausweises (rechts oben) benutzt.
      </p>
      <div v-if="profileImage">
        <div class="img-container w-25 p-3">
          <img
            class="img-fluid"
            :src="'data:image/png;base64, ' + profileImage.encoded_image_data"
            @click="show = !show"
            width="300"
            height="300"
          />
        </div>
      </div>
      <div v-else class="img-container w-25 p-3">
        <img
          class="img-fluid crop-upload-container"
          @click="show = !show"
          src="/icons/upload-photo.svg"
          alt=""
        />
      </div>
      <cropUpload
        v-show="show"
        field="img"
        @crop-success="cropSuccess"
        @crop-upload-success="cropUploadSuccess"
        @crop-upload-fail="cropUploadFail"
        @crop-delete="deleteCurrentLogo"
        v-model="show"
        :width="300"
        :height="300"
        :langExt="langExt"
        noCircle
        :params="params"
        :headers="headers"
        img-format="png"
        ref="uploader"
      >
      </cropUpload>
      <hr width="88%" size="2" />
      <div>
        <h5>Logo (links oben)</h5>
        <div v-if="this.layout.based_on_template_id !== '1bb04ad8-cfe0-46b1-9559-cf5a205482a3'">
          <p>
            Diese Grafik (optional) wird als Logo auf der Vorderseite des
            Ausweises (links oben) benutzt.
          </p>
        </div>
        <div v-else>
          <p>
            Diese Grafik ist bei dem DFV Dienstausweis fest vorgegeben und kann nicht verändert werden!
          </p>
        </div>
        <div v-if="this.layout.header_picture_id">
          <div class="img-container w-25 p-3">
            <img
              class="image-preview img-fluid mt-4"
              :src="'data:image/png;base64, ' + headerPictureImgSrc"
              :style="{
                cursor:
                  layout.based_on_template_id ===
                  '1bb04ad8-cfe0-46b1-9559-cf5a205482a3'
                    ? 'default'
                    : 'pointer',
              }"
              :clickable="
                layout.based_on_template_id !==
                  '1bb04ad8-cfe0-46b1-9559-cf5a205482a3'
              "
              @click="
                layout.based_on_template_id !==
                  '1bb04ad8-cfe0-46b1-9559-cf5a205482a3' && (showH = !showH)
              "
              width="300"
              height="300"
            />
          </div>
        </div>
        <div v-else class="upload-card-img">
          <img
            class="w-75 crop-upload-container"
            :style="{
              cursor:
                layout.based_on_template_id ===
                '1bb04ad8-cfe0-46b1-9559-cf5a205482a3'
                  ? 'default'
                  : 'pointer',
            }"
            @click="
              layout.based_on_template_id !==
                '1bb04ad8-cfe0-46b1-9559-cf5a205482a3' && (showH = !showH)
            "
            src="/icons/upload-photo.svg"
            alt=""
          />
        </div>
      </div>
      <cropUpload
        v-show="showH"
        field="img"
        @crop-success="cropSuccessHeader"
        @crop-upload-success="cropUploadSuccess"
        @crop-upload-fail="cropUploadFailHeader"
        @crop-delete="deleteCurrentLogoHeader"
        v-model="showH"
        :width="300"
        :height="300"
        :langExt="langExt"
        noCircle
        :params="params"
        :headers="headers"
        img-format="png"
        ref="uploader"
      >
      </cropUpload>
      <hr width="88%" size="2" />
      <div>
        <h5>Logo (Hintergrund)</h5>
        <div v-if="this.layout.based_on_template_id !== '1bb04ad8-cfe0-46b1-9559-cf5a205482a3'">
          <p>
            Diese Grafik (optional) wird als Hintergrund auf der Vorderseite des Ausweises benutzt.
          </p>
        </div>
        <div v-else>
          <p>
            Diese Grafik ist bei dem DFV Dienstausweis fest vorgegeben und kann nicht verändert werden!
          </p>
        </div>
        <div v-if="this.layout.background_picture_id">
          <div class="img-container w-25 p-3">
            <img
              class="image-preview img-fluid mt-4"
              :src="'data:image/png;base64, ' + backgroundImgSrc"
              :style="{
                cursor:
                  layout.based_on_template_id ===
                  '1bb04ad8-cfe0-46b1-9559-cf5a205482a3'
                    ? 'default'
                    : 'pointer',
              }"
              @click="
                layout.based_on_template_id !==
                  '1bb04ad8-cfe0-46b1-9559-cf5a205482a3' && (showB = !showB)
              "
              width="300"
              height="300"
            />
          </div>
        </div>
        <div v-else class="upload-card-img">
          <img
            class="w-75 crop-upload-container"
            :style="{
              cursor:
                layout.based_on_template_id ===
                '1bb04ad8-cfe0-46b1-9559-cf5a205482a3'
                  ? 'default'
                  : 'pointer',
            }"
            @click="
              layout.based_on_template_id !==
                '1bb04ad8-cfe0-46b1-9559-cf5a205482a3' && (showB = !showB)
            "
            src="/icons/upload-photo.svg"
            alt=""
          />
        </div>
      </div>

      <cropUpload
        v-show="showB"
        field="img"
        @crop-success="cropSuccessBack"
        @crop-upload-success="cropUploadSuccess"
        @crop-upload-fail="cropUploadFailBack"
        @crop-delete="deleteCurrentLogoBack"
        v-model="showB"
        :width="300"
        :height="300"
        :langExt="langExt"
        noCircle
        :params="params"
        :headers="headers"
        img-format="png"
        ref="uploader"
      >
      </cropUpload>
    </vs-col>
  </vs-row>
</template>

<script>
import Vue from "vue";
import store from "@/store/index";
import errorNotifier from "@/plugins/errorNotifier";
import CropUpload from "@/plugins/mpcropupload/upload2.vue";

import _ from "lodash";

export default Vue.extend({
  props: ["layout", "organization"],

  components: {
    CropUpload,
  },
  data() {
    return {
      // image64: '',
      backgroundImgSrc: null,
      headerPictureImgSrc: null,

      image: {
        size: "Pixel",
        height: "30",
        width: "30",
      },
      imageError: "",
      // CROP/UPLOAD
      show: false,
      showH: false,
      showB: false,
      params: {
        token: "123456798",
        name: "avatar",
      },
      headers: {
        smail: "*_~",
      },
      imgDataUrl: "",
      langExt: {
        titel: "Grafik bearbeiten",
        hint:
          "Klicken um Grafik auszuwählen oder Grafik direkt hierher ablegen",
        loading: "Wird hochgeladen...",
        noSupported:
          "Ihr Browser wird nicht unterstützt. Bitte verwenden Sie IE10+ oder einen anderen Browser.",
        uploadMessage:
          "Mit dem Upload bestätigen Sie, im Besitz der Nutzungsrechte für die Grafik zu sein.",
        uploadImgSize:
          "[Nur JPG-, PNG- oder SVG-Datei mit 300 x 300 Pixel und max. 1 MB Dateigröße]",
        success: "Erfolgreich hochgeladen",
        fail: "Hochladen fehlgeschlagen",
        preview: "Vorschau",
        btn: {
          off: "Abbrechen",
          close: "Schliessen",
          back: "Zurück",
          save: "Speichern",
          delete: "Löschen",
        },
        error: {
          onlyImg: "Nur Grafiken im Format: JPG,JPEG,PNG,GIF,SVG oder BMP",
          outOfSize: "Grafik zu groß, Maximum: ",
          lowestPx: "Grafik zu klein, Minimum: ",
        },
      },
    };
  },

  watch: {
    organization: {
      deep: true,
      async handler(val) {
        if (this.organization.logo_image_id) {
          await this.$store.dispatch(
            "core/loadImage",
            this.organization.logo_image_id
          );
        } else {
          this.$store.commit("core/setImage", null);
        }
      },
    },
    layout: {
      deep: true,
      async handler() {
        this.backgroundImgSrc = await this.$store.dispatch(
          "core/getImage64",
          this.layout.background_picture_id
        );
        this.headerPictureImgSrc = await this.$store.dispatch(
          "core/getImage64",
          this.layout.header_picture_id
        );
      },
    },
  },

  computed: {
    profileImage() {
      return store.getters["core/getImage"];
    },
  },

  methods: {
    async deleteCurrentLogo() {
      let status = await this.$store.dispatch(
        "core/deleteImage",
        this.organization.logo_image_id
      );
      if (status != 200) {
        errorNotifier(status);
        return;
      }
      this.organization.logo_image_id = null;
    },
    async deleteCurrentLogoHeader() {
      let status = await this.$store.dispatch(
        "core/deleteImage",
        this.layout.header_picture_id
      );
      if (status != 200) {
        errorNotifier(status);
        return;
      }
      this.layout.header_picture_id = null;
    },
    async deleteCurrentLogoBack() {
      this.layout.background_picture_id = null;
    },
    cropSuccess(imgDataUrl, field) {
      this.imgDataUrl = imgDataUrl;
      let baseString = imgDataUrl.split(",")[1];

      this.$store.dispatch("core/createImage", baseString).then((resp) => {
        this.organization.logo_image_id = resp.data.id;
      });
      this.$refs.uploader.setStep(1);
    },
    cropSuccessBack(imgDataUrl, field) {
      this.imgDataUrl = imgDataUrl;
      let baseString = imgDataUrl.split(",")[1];

      this.$store.dispatch("core/createImage", baseString).then((resp) => {
        this.layout.background_picture_id = resp.data.id;
      });
      this.$refs.uploader.setStep(1);
    },
    cropSuccessHeader(imgDataUrl, field) {
      this.imgDataUrl = imgDataUrl;
      let baseString = imgDataUrl.split(",")[1];

      this.$store.dispatch("core/createImage", baseString).then((resp) => {
        this.layout.header_picture_id = resp.data.id;
      });
      this.$refs.uploader.setStep(1);
    },

    cropUploadSuccess(jsonData, field) {},

    cropUploadFail(status, field) {
      this.organization.logo_image_id = null;
      this.$refs.uploader.setStep(1);
    },
    cropUploadFailBack(status, field) {
      this.layout.background_picture_id = null;
      this.$refs.uploader.setStep(1);
    },
    cropUploadFailHeader(status, field) {
      this.layout.header_picture_id = null;
      this.$refs.uploader.setStep(1);
    },
  },
});
</script>
<style scoped>
.content-section {
  height: 55vh;
  margin-top: 40px;
}
.crop-upload-container:hover {
  opacity: 0.7;
  transition: opacity 0.3s ease;
}
</style>
