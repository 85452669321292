<template>
  <vs-row class="mt-4">
    <vs-col vs-w="12">
        <vs-input class="inputx date-input-md mt-3" :label-placeholder="$t('description')" 
          v-model="organization.name" />
        <vs-input :disabled="isPreconfLayout && layout.preconfigured_layout_id==1" class="inputx date-input-md mt-3" :label-placeholder="$t('issuing-authority')"
          v-model="organization.issuing_authority_name" /> 
        <vs-select v-if="organization.id===''" @change="onChange($event)" label="Layout" class="inputx date-input-md mt-3" v-model="organization.id_card_layout_id">
          <vs-select-item :key="layout.id"
              :value="layout.id" :text="layout.name" v-for="layout in customerTemplates" />
        </vs-select>
    </vs-col>
  </vs-row>
</template>

<script>
import Vue from 'vue';


export default Vue.extend({
  props: ["organization", "layout" ],

  data() {
    return {
      isPreconfLayout:false,
    };
  },

  async mounted() {
    this.$store.dispatch('layout/loadTemplates');
    await this.$store.dispatch('layout/loadLayout', this.organization.id_card_layout_id);
    const temp=this.$store.getters['layout/getLayout'];
    if (temp.preconfigured_layout_id >= 1) {
      this.isPreconfLayout=true;
    } else {
      this.isPreconfLayout=false;
    }
  },

  computed: {
    customerTemplates() {
      return this.$store.getters['layout/getTemplates'];
    },

  },
  methods: {
    async onChange(event) {
      await this.$store.dispatch('layout/loadLayout', event);
      const temp=this.$store.getters['layout/getLayout'];
      this.isPreconfLayout=(temp.preconfigured_layout_id > 0) ;
      if (temp.preconfigured_layout_id === 1) {
          this.organization.issuing_authority_name="Luftfahrtbundesamt";

      } else if (this.organization.issuing_authority_name==="Luftfahrtbundesamt"){
        this.organization.issuing_authority_name=null;
      }
    },
  }
});
</script>