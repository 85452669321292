var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('vs-row',{staticClass:"w-100 mt-4"},[_c('vs-col',{attrs:{"vs-w":"12"}},[_c('h5',[_vm._v("Logo (Organisation / rechts oben)")]),_c('p',[_vm._v(" Diese Grafik wird sowohl als Organisations-Logo in der App, als auch auf der Vorderseite des Ausweises (rechts oben) benutzt. ")]),(_vm.profileImage)?_c('div',[_c('div',{staticClass:"img-container w-25 p-3"},[_c('img',{staticClass:"img-fluid",attrs:{"src":'data:image/png;base64, ' + _vm.profileImage.encoded_image_data,"width":"300","height":"300"},on:{"click":function($event){_vm.show = !_vm.show}}})])]):_c('div',{staticClass:"img-container w-25 p-3"},[_c('img',{staticClass:"img-fluid crop-upload-container",attrs:{"src":"/icons/upload-photo.svg","alt":""},on:{"click":function($event){_vm.show = !_vm.show}}})]),_c('cropUpload',{directives:[{name:"show",rawName:"v-show",value:(_vm.show),expression:"show"}],ref:"uploader",attrs:{"field":"img","width":300,"height":300,"langExt":_vm.langExt,"noCircle":"","params":_vm.params,"headers":_vm.headers,"img-format":"png"},on:{"crop-success":_vm.cropSuccess,"crop-upload-success":_vm.cropUploadSuccess,"crop-upload-fail":_vm.cropUploadFail,"crop-delete":_vm.deleteCurrentLogo},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}}),_c('hr',{attrs:{"width":"88%","size":"2"}}),_c('div',[_c('h5',[_vm._v("Logo (links oben)")]),(this.layout.based_on_template_id !== '1bb04ad8-cfe0-46b1-9559-cf5a205482a3')?_c('div',[_c('p',[_vm._v(" Diese Grafik (optional) wird als Logo auf der Vorderseite des Ausweises (links oben) benutzt. ")])]):_c('div',[_c('p',[_vm._v(" Diese Grafik ist bei dem DFV Dienstausweis fest vorgegeben und kann nicht verändert werden! ")])]),(this.layout.header_picture_id)?_c('div',[_c('div',{staticClass:"img-container w-25 p-3"},[_c('img',{staticClass:"image-preview img-fluid mt-4",style:({
              cursor:
                _vm.layout.based_on_template_id ===
                '1bb04ad8-cfe0-46b1-9559-cf5a205482a3'
                  ? 'default'
                  : 'pointer',
            }),attrs:{"src":'data:image/png;base64, ' + _vm.headerPictureImgSrc,"clickable":_vm.layout.based_on_template_id !==
                '1bb04ad8-cfe0-46b1-9559-cf5a205482a3',"width":"300","height":"300"},on:{"click":function($event){_vm.layout.based_on_template_id !==
                '1bb04ad8-cfe0-46b1-9559-cf5a205482a3' && (_vm.showH = !_vm.showH)}}})])]):_c('div',{staticClass:"upload-card-img"},[_c('img',{staticClass:"w-75 crop-upload-container",style:({
            cursor:
              _vm.layout.based_on_template_id ===
              '1bb04ad8-cfe0-46b1-9559-cf5a205482a3'
                ? 'default'
                : 'pointer',
          }),attrs:{"src":"/icons/upload-photo.svg","alt":""},on:{"click":function($event){_vm.layout.based_on_template_id !==
              '1bb04ad8-cfe0-46b1-9559-cf5a205482a3' && (_vm.showH = !_vm.showH)}}})])]),_c('cropUpload',{directives:[{name:"show",rawName:"v-show",value:(_vm.showH),expression:"showH"}],ref:"uploader",attrs:{"field":"img","width":300,"height":300,"langExt":_vm.langExt,"noCircle":"","params":_vm.params,"headers":_vm.headers,"img-format":"png"},on:{"crop-success":_vm.cropSuccessHeader,"crop-upload-success":_vm.cropUploadSuccess,"crop-upload-fail":_vm.cropUploadFailHeader,"crop-delete":_vm.deleteCurrentLogoHeader},model:{value:(_vm.showH),callback:function ($$v) {_vm.showH=$$v},expression:"showH"}}),_c('hr',{attrs:{"width":"88%","size":"2"}}),_c('div',[_c('h5',[_vm._v("Logo (Hintergrund)")]),(this.layout.based_on_template_id !== '1bb04ad8-cfe0-46b1-9559-cf5a205482a3')?_c('div',[_c('p',[_vm._v(" Diese Grafik (optional) wird als Hintergrund auf der Vorderseite des Ausweises benutzt. ")])]):_c('div',[_c('p',[_vm._v(" Diese Grafik ist bei dem DFV Dienstausweis fest vorgegeben und kann nicht verändert werden! ")])]),(this.layout.background_picture_id)?_c('div',[_c('div',{staticClass:"img-container w-25 p-3"},[_c('img',{staticClass:"image-preview img-fluid mt-4",style:({
              cursor:
                _vm.layout.based_on_template_id ===
                '1bb04ad8-cfe0-46b1-9559-cf5a205482a3'
                  ? 'default'
                  : 'pointer',
            }),attrs:{"src":'data:image/png;base64, ' + _vm.backgroundImgSrc,"width":"300","height":"300"},on:{"click":function($event){_vm.layout.based_on_template_id !==
                '1bb04ad8-cfe0-46b1-9559-cf5a205482a3' && (_vm.showB = !_vm.showB)}}})])]):_c('div',{staticClass:"upload-card-img"},[_c('img',{staticClass:"w-75 crop-upload-container",style:({
            cursor:
              _vm.layout.based_on_template_id ===
              '1bb04ad8-cfe0-46b1-9559-cf5a205482a3'
                ? 'default'
                : 'pointer',
          }),attrs:{"src":"/icons/upload-photo.svg","alt":""},on:{"click":function($event){_vm.layout.based_on_template_id !==
              '1bb04ad8-cfe0-46b1-9559-cf5a205482a3' && (_vm.showB = !_vm.showB)}}})])]),_c('cropUpload',{directives:[{name:"show",rawName:"v-show",value:(_vm.showB),expression:"showB"}],ref:"uploader",attrs:{"field":"img","width":300,"height":300,"langExt":_vm.langExt,"noCircle":"","params":_vm.params,"headers":_vm.headers,"img-format":"png"},on:{"crop-success":_vm.cropSuccessBack,"crop-upload-success":_vm.cropUploadSuccess,"crop-upload-fail":_vm.cropUploadFailBack,"crop-delete":_vm.deleteCurrentLogoBack},model:{value:(_vm.showB),callback:function ($$v) {_vm.showB=$$v},expression:"showB"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }