<template>
  <div class="amendments-container">
    <h1 style="margin-left: 20px;">{{$t("organizations")}}</h1>
    <div class="idcard-con">
      <div class="con mt-4">
        <vs-input class="search-input org-width" icon-after="search" :placeholder="$t('organization-search')" v-model="oranization_search" icon="search" icon-no-border label-placeholder="icon-no-border" />

        <div class="outer-con2">
          <div class="table-container" style="overflow-y: auto; max-width: 100%;">
            <table>
              <thead>
                <tr>
                  <th @click="sort('name')">{{$t("organization")}}<div class="arrow" v-if="'name' == currentSort" v-bind:class="ascending ? 'arrow_up' : 'arrow_down'"></div></th>
                </tr>
              </thead>
              <tbody>
                <tr class="cursor" v-for="organization in organizations" :key="organization.id" @click="editOrganization(organization)">
                  <td>{{organization.name}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <vs-button class="organ-btn" @click="addOrganization()" color="primary" type="filled" icon="add"> {{$t('organization-new')}}</vs-button>

    <PopupOrganization ref="popupOrganization" />
  </div>
</template>

<script>
  import Vue from "vue";
  import PopupOrganization from "../components/organization/PopupOrganization.vue";
  // import AddOrganizationPopup from '../components/organization/PopupAddOrganization.vue.orig'

  export default Vue.extend({
    name: "Organizations",
    components: {
      PopupOrganization,
    },

    async mounted() {
      await this.$store.dispatch("organization/loadOrganizations");
    },

    methods: {
      sort:function(s) {
        if(s === this.currentSort) {
          this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
          this.ascending = !this.ascending;
        }
        this.currentSort = s;
      },
      editOrganization(organisation) {
        const popup = this.$refs.popupOrganization;
        popup.openEdit(organisation.id);
      },
      addOrganization() {
        const popup = this.$refs.popupOrganization;
        popup.open();
      },
    },

    computed: {
      organizations:function() {
        let org = this.$store.getters["organization/getOrganizations"];
        const user = this.$store.getters['auth/getUser']
        if (!user.is_customer_admin) {
          if (user.organisation_mappings) {
              org = org.filter(u => {
                  return user.organisation_mappings.find(o => {
                      if (o.organisation_id === u.id) {
                      if (o.organisation_permission) {
                          return true
                        }
                      }
                  });
              });
          } else {
              return null;
          }
        }

        if (this.oranization_search !== '') {
          return org.filter(o => ((o.name).toLowerCase()).includes((this.oranization_search).toLowerCase()));
        }
        return org.sort((a,b)=> {
            let modifier=1;
            if(this.currentSortDir === 'desc') modifier = -1;
            if(a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
            if(a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
            return 0;
        })
      },
    },

    data() {
      return {
        oranization_search: "",
        name_search: "",
        search: "",
        ascending: false,
        currentSort:'name',
        currentSortDir:'asc'
      };
    },
  });
</script>
<style></style>
