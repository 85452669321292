<template>
  <vs-popup :title="title" :active.sync="showPopup">
    <div :class="{ popup: isEdit, 'p-4': true }">
      <!-- MENU -->
      <vs-row class="menu" v-if="isEdit">
        <div class="item me-2 p-2" :class="{'active': menu ==='general'}" @click="menu='general'">
          <span>{{$t('general')}}</span>
        </div>

        <div v-if="isEdit && !isPreconfLayout" class="item me-2 p-2" :class="{'active': menu ==='fields'}" @click="menu='fields'">
          <span>{{$t('fields')}}</span>
        </div>
        <div v-if="isEdit && !isPreconfLayout" class="item me-2 p-2" :class="{'active': menu ==='logo'}" @click="menu='logo'">
          <span>{{$t('logo')}}</span>
        </div>
        <div v-if="isEdit" class="item me-2 p-2" :class="{'active': menu ==='frontside'}" @click="menu='frontside'">
          <span>{{$t('frontside')}}</span>
        </div>
        <div v-if="isEdit" class="item me-2 p-2" :class="{'active': menu ==='backside'}" @click="menu='backside'">
          <span>{{$t('backside')}}</span>
        </div>
      </vs-row>


      <!-- POPUP BODY -->
      <vs-row class="content-section mt-4">
        <General v-show="menu==='general'" :organization="organization" :layout="layout" />
        <Fields v-show="menu==='fields'" :layout="layout" />
        <Logo v-show="menu==='logo'" :organization="organization" :layout="layout"  />
        <FrontSide v-show="menu==='frontside'" :organization="organization" :layout="layout" />
        <BackSide v-show="menu==='backside'" :layout="layout" />
      </vs-row>


      <!-- ACTION BUTTONS -->
      <vs-row vs-w="12">
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <vs-button v-if="isEdit && menu==='general'" class="pop-btn" color="danger" type="filled" icon="delete"
            @click="deleteConfirm()"> {{$t('delete')}} </vs-button>
        </vs-col>

        <vs-col vs-type="flex" vs-justify="right" vs-align="right" vs-w="6">
          <vs-button v-if="isEdit" class="pop-btn" color="primary" type="filled" icon="done"
            @click="updateOrganization()"> {{$t('save')}}</vs-button>
          <vs-button v-else class="pop-btn" color="primary" type="filled" icon="done" @click="addOrganization()">
            {{$t('create')}}</vs-button>

          <vs-button class="pop-btn" color="primary" type="filled" icon="close" @click="showPopup=!showPopup">
            {{$t('cancel')}} </vs-button>
        </vs-col>
      </vs-row>


      <!-- DEBUG MODE -->
      <vs-row v-if="isDebugMode">
        <h1>Orga</h1>
        <p>{{organization}}</p>
        <h1>Layout</h1>
        <p>{{layout}}</p>
      </vs-row>

    </div>
  </vs-popup>
</template>

<script>
  import Vue from "vue";
  import _ from "lodash";
  
  import General from "./tabs/general.vue";
  import Logo from "./tabs/logo.vue";
  import Fields from "@/components/layout/tabs/fields.vue";
  import FrontSide from "@/components/layout/tabs/frontside.vue";
  import BackSide from "@/components/layout/tabs/backside.vue";
  import errorNotifier from "@/plugins/errorNotifier";

  export default Vue.extend({
    components: { General, Fields, Logo, FrontSide, BackSide },

    computed: {
      title() {
        if (this.isEdit) {
          return this.$t("organization-edit") + ` > ` + this.organization.name;
        }
        return this.$t("organization-new");
      },
    },

    data() {
      return {
        menu: "general",
        showPopup: false,
        isEdit: false,
        isPreconfLayout:false,
        organization: {},
        fields: {},
        layout: {},
        basedOnLayoutId: "",
      };
    },

    methods: {
      initOrganizationObj() {
        this.organization = {
          //  "id": "2d145231-8122-46ed-ae5e-edf7a54f288c",
          //  "customer_id": "5ad57505-de77-41bd-be22-1bd8b2e57c80",
          //  "name": "test",
          //  "logo_image_id": null,
          //  "id_card_layout_id": null,
          //  "issuing_authority_name": "test",
          //  "created_by": "f47b729c-9098-49a7-8742-eb6b31bebe97",
          //  "last_modified_by": null,
          //  "created_at": "2022-03-07T20:30:19.326Z",
          //  "updated_at": "2022-03-07T20:30:19.326Z"
          id: "",
          customer_id: "",
          name: "",
          logo_image_id: "",
          id_card_layout_id: "",
          issuing_authority_name: "",
          created_by: "",
          last_modified_by: "",
          created_at: "",
          updated_at: "",
        };
        this.layout = {
          name: "Neues Layout für Organisation",
          is_template: false,
          title: "Neue Organisation",
          stylesheet: {
            header_background_color: "#ffffff",
            body_background_color: "#ffffff",
            header_text_color: "#000000",
            body_text_color: "#000000",
            // fields_front: [
            //   [
            //   { key: "id_number" }, 
            //   { key: "date_of_birth" }, 
            //   { key: "valid_until" }, 
            //   { key: "issuing_authority_name" }, 
            //   { key: "function" }
            // ]],
            fields_front: [
              [
                { key: "date_of_birth" },
                { key: "role"}
              ],
              [
                { key: "valid_until" },
                { key: "issuing_authority_name" },
              ]
            ],
            fields_back: [],
          },
          // header_picture_id: "",
          backside_text_additional: "Hinten: Text Zusatz",
          backside_footer_text: "Hinten: Fußzeile",
          based_on_template_id: "",
          extra_1_caption: "",
          extra_2_caption: "",
          extra_3_caption: "",
          extra_4_caption: "",
          extra_5_caption: "",
          extra_6_caption: "",
          extra_1_single_value: "",
          extra_2_single_value: "",
          extra_3_single_value: "",
          extra_4_single_value: "",
          extra_5_single_value: "",
          extra_6_single_value: "",
          backside_text: "Hinten: Text",
        };
      },
      async openEdit(id) {
        // Load Organization
        this.menu = "general";
        await this.$store.dispatch("organization/loadOrganization", id);
        const organization = this.$store.getters["organization/getOrganization"];
        this.organization = _.cloneDeep(organization);

        // Load Layout
        await this.$store.dispatch("layout/loadLayout", organization.id_card_layout_id);
        const layout = this.$store.getters["layout/getLayout"];
        this.layout = _.cloneDeep(layout);

        this.showPopup = true;
        this.isEdit = true;
        if (this.layout.preconfigured_layout_id !== null && this.layout.is_template === true) {
          this.isPreconfLayout=true;
        }
        else {
          this.isPreconfLayout=false;
        }
      },

      open() {
        this.initOrganizationObj();
        this.showPopup = true;
        this.isEdit = false;
        this.isPreconfLayout = false;
        this.menu = "general";
      },

      async addOrganization() {
        if (this.organization.name == '') {
          this.$vs.notify({
            color: "danger",
            position: "bottom-right",
            title: "Bitte eine Bezeichnung angeben!",
          });
          return
        }

        if (this.organization.issuing_authority_name == '') {
          this.$vs.notify({
            color: "danger",
            position: "bottom-right",
            title: "Bitte einen Austeller angeben!",
          });
          return
        }

        if (this.organization.id_card_layout_id == '') {
          this.$vs.notify({
            color: "danger",
            position: "bottom-right",
            title: "Bitte ein Layout auswählen!",
          });
          return
        }

        // Copy Layout

        // Make other Layout Adjustments
        const basedOnLayoutId = this.organization.id_card_layout_id;
        
        await this.$store.dispatch('layout/loadLayout', basedOnLayoutId)
        this.layout = _.cloneDeep(this.$store.getters['layout/getLayout'])
        if (this.layout.preconfigured_layout_id !== null && this.layout.is_template === true) {
          this.isPreconfLayout=true;
        }
        else{
          // Change copied information
          this.layout.is_template = false
          this.layout.template_of_customer_id = null
          this.layout.name = this.organization.name;
          this.layout.based_on_template_id = basedOnLayoutId;
          this.layout.id = null

          // Create New Orgnaization layout
          let status2 = await this.$store.dispatch("layout/createLayout", this.layout);
          if (status2 != 201) {
            errorNotifier(status2)
            return
          }
        }

        let organization = _.cloneDeep(this.organization);
        if (this.layout.preconfigured_layout_id === 1 && this.layout.is_template === true) {
          organization.id_card_layout_id =basedOnLayoutId;
          organization.issuing_authority_name= "Luftfahrtbundesamt";
        }
        else{
          const layout = this.$store.getters["layout/getLayout"];
          organization.id_card_layout_id = layout.id;
        }
        let status = await this.$store.dispatch("organization/createOrganization", organization);

        if (status === 201) {
          this.$vs.notify({
            color: "success",
            position: "bottom-right",
            title: "Organisation wurde hinzugefügt",
          });
        } else {
          errorNotifier(status)
        }

        this.showPopup = false;
      },

      async updateOrganization() {
        let status = await this.$store.dispatch("organization/updateOrganization", this.organization);
        if (status != 200) {
          errorNotifier(status)
          return
        }
        if (!this.isPreconfLayout) {
          status = await this.$store.dispatch("layout/updateLayout", this.layout);
        }
        if (status === 200) {         
          this.$vs.notify({
            color: "success",
            position: "bottom-right",
            title: "Organisation wurde gespeichert",
          });
        } else {
          errorNotifier(status)
        }

        this.showPopup = false;
      },

      deleteConfirm() {
        this.$vs.dialog({
          type: "confirm",
          color: "danger",
          title: `Löschen bestätigen`,
          text: "Wollen Sie wirklich die aktuelle Organisation unwiderruflich löschen?",
          accept: this.deleteOrganization,
          acceptText: "Löschen",
          cancelText: "Abbrechen"
        });
      },

      async deleteOrganization() {
        let status = await this.$store.dispatch("organization/deleteOrganization", this.organization.id);
        
        if (status === 200) {
          this.$vs.notify({
            color: "success",
            position: "bottom-right",
            title: "Organisation wurde gelöscht",
          });
        } else {
          errorNotifier(status)
        }

        this.showPopup = false;
      },
    },
  });
</script>
